// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-tsx": () => import("/Volumes/Data/Projects/birkir.github.io/src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-pages-404-tsx": () => import("/Volumes/Data/Projects/birkir.github.io/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Volumes/Data/Projects/birkir.github.io/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("/Volumes/Data/Projects/birkir.github.io/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Volumes/Data/Projects/birkir.github.io/.cache/data.json")

